

/******************* SETUP ****************/
body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eee;
}


/*
h1
{
  font-size: 45px;
  color: #374657;
  font-weight: 700;
}

h2
{
  font-size: 36px;
  color: #374657;
  font-weight: 700;
}


h2 span.slash
{
  letter-spacing: 8px;
}

h3
{
  font-size: 22px;
  color: #374657;
  font-weight: 700;
}

h4
{
  font-size: 18px;
  color: #374657;
  font-weight: 700;
}

p, li
{
  font-size: 18px;
  color: #747679;
  font-weight: 400;
}

a
{
  color: inherit;
}

header *, footer *{
  text-decoration: none;
}
*/

.MainView
{
  margin-top:60px;
  margin-left:240px;
}


@media (max-width: 740px) {
  .MainView
  {
    margin-left:0px;
  }
}

.MuiButton-containedPrimary
{
  background:#207367ff !important ;
}

.MuiButton-outlinedPrimary 
{
  border:solid #207367ff 3px !important ;
  color:#207367 !important ;
  font-weight: 600  !important;
}


/******************* ALL *****************/

.ErrorCell
{
  padding-right: 0  !important;
  line-height: 0 !important;
  color:#207367ff  !important;
  width: 24px !important;
}

/******************* HEADER **********************/

.MuiAppBar-colorPrimary 
{
  background:#207367ff !important ;
}

.HeaderLogo 
{
  height: 40px;
  margin-right: 15px;
}

a .MuiListItemText-primary {
  color: #333;
}

.DrawFooter {
  width: 100%;
  position: absolute;
  background: #fff;
  padding-top: 5px;
  color:#333 !important;
  font-size: 14px !important;
  bottom: 0 !important;
  padding-Bottom: 5px !important;
  text-align: center !important;
}
.DrawFooter a {
  text-decoration: none !important;
  color:#333 !important;
  font-weight: 600 !important;
}

/******************* LOADING View ****************/

.loadingView
{
  padding-top: 20px;
  text-align: center;
  font-size: 30px;
}
.loading-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border: 3px solid #207367;
  border-radius: 5px;
  overflow: hidden;
  margin: auto;
}

.loading-lines {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 87%;
  margin: auto;
}

.loading-line {
  width: 3px;
  height: 0;
  border-radius: 1.5px;
  background-color: #207367;
  animation: lineAnimation 1s infinite;
}

.loading-line-1 {
  animation-duration: 1.2s;
  animation-delay: 0.1s;
}

.loading-line-2 {
  animation-duration: 0.8s;
  animation-delay: 0.3s;
}

.loading-line-3 {
  animation-duration: 1.4s;
  animation-delay: 0.5s;
}

.loading-line-4 {
  animation-duration: 1.1s;
  animation-delay: 0.2s;
}

.loading-line-5 {
  animation-duration: 0.9s;
  animation-delay: 0.4s;
}

.loading-line-6 {
  animation-duration: 1.3s;
  animation-delay: 0.6s;
}

.loading-line-7 {
  animation-duration: 1s;
  animation-delay: 0.3s;
}

@keyframes lineAnimation {
  0%,
  100% {
    height: 5px;
  }

  50% {
    height: 40px;
  }
}

/******************* 404 View ****************/

.View404
{
  text-align: center;
  padding: 30px 20px;
}

.View404 h1
{
  font-size: 20px;
}

.View404 p
{
  font-size: 16px;
}

/******************* Login View ******************/

.LoginView
{
  padding: 50px 0px;

  text-align: center;
}


.LoginView h1
{
  font-size: 40px;
  margin-bottom: 0;
  color:#207367ff;
}

.LoginView h2
{
  margin-top: 0;
  margin-bottom: 50px;
}

.LoginView img
{
  max-width: 70px;
}


/************* MAP **************/
#map 
{
  position: absolute;
  top:56px;
  bottom:0;
  left:240px;
  right:0;

}


@media (max-width: 740px) {
  #map
  {
    left:0;
  }
}


















/*Css Code from Bootstrap 3.3.7* /
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
display: none !important;
}
@media (max-width: 767px) {
.visible-xs {
display: block !important;
}
table.visible-xs {
display: table !important;
}
tr.visible-xs {
display: table-row !important;
}
th.visible-xs,
td.visible-xs {
display: table-cell !important;
}
}
@media (max-width: 767px) {
.visible-xs-block {
display: block !important;
}
}
@media (max-width: 767px) {
.visible-xs-inline {
display: inline !important;
}
}
@media (max-width: 767px) {
.visible-xs-inline-block {
display: inline-block !important;
}
}
@media (min-width: 768px) and (max-width: 991px) {
.visible-sm {
display: block !important;
}
table.visible-sm {
display: table !important;
}
tr.visible-sm {
display: table-row !important;
}
th.visible-sm,
td.visible-sm {
display: table-cell !important;
}
}
@media (min-width: 768px) and (max-width: 991px) {
.visible-sm-block {
display: block !important;
}
}
@media (min-width: 768px) and (max-width: 991px) {
.visible-sm-inline {
display: inline !important;
}
}
@media (min-width: 768px) and (max-width: 991px) {
.visible-sm-inline-block {
display: inline-block !important;
}
}
@media (min-width: 960px) and (max-width: 1280px) {
.visible-md {
display: block !important;
}
table.visible-md {
display: table !important;
}
tr.visible-md {
display: table-row !important;
}
th.visible-md,
td.visible-md {
display: table-cell !important;
}
}
@media (min-width: 960px) and (max-width: 1280px) {
.visible-md-block {
display: block !important;
}
}
@media (min-width: 960px) and (max-width: 1280px) {
.visible-md-inline {
display: inline !important;
}
}
@media (min-width: 960px) and (max-width: 1280px) {
.visible-md-inline-block {
display: inline-block !important;
}
}
@media (min-width: 1200px) {
.visible-lg {
display: block !important;
}
table.visible-lg {
display: table !important;
}
tr.visible-lg {
display: table-row !important;
}
th.visible-lg,
td.visible-lg {
display: table-cell !important;
}
}
@media (min-width: 1200px) {
.visible-lg-block {
display: block !important;
}
}
@media (min-width: 1200px) {
.visible-lg-inline {
display: inline !important;
}
}
@media (min-width: 1200px) {
.visible-lg-inline-block {
display: inline-block !important;
}
}
@media (max-width: 767px) {
.hidden-xs {
display: none !important;
}
}
@media (min-width: 768px) and (max-width: 991px) {
.hidden-sm {
display: none !important;
}
}
@media (min-width: 960px) and (max-width: 1280px) {
.hidden-md {
display: none !important;
}
}
@media (min-width: 1200px) {
.hidden-lg {
display: none !important;
}
}



pre {
  background: #eee;
  padding: 10px 20px;
}

*/